import { Injectable } from '@angular/core';
import Amplify, { Auth } from 'aws-amplify';
import { AppConfigService } from './app-config.service';
import { CognitoAuthConfig } from '../interfaces/cognito/cognito-auth-config';
import { CognitoOauth } from '../interfaces/cognito/cognito-oauth';
import { CognitoSecrets } from '../interfaces/cognito/cognito-secrets';

@Injectable({
  providedIn: 'root'
})
export class CognitoConfigService {

  constructor() { }

  load(): void {
    Amplify.configure({
      Auth: this.getCognitoAuthConfig(AppConfigService.settings.cognitoSecrets),
      API: {
        endpoints: [
          {
            name: 'MyAPIGateway',
            endpoint: AppConfigService.settings.backendUrl,
            custom_header: async () => {
              return {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
              };
            }
          }
        ]
      }
    });
  }

  private getCognitoAuthConfig(cognitoSecrets: CognitoSecrets): CognitoAuthConfig {
    const poolId = this.getPoolIdFromIssuer(cognitoSecrets.issuer);
    const regionValue = this.getRegionFromPoolId(poolId);
    const domainValue = this.getDomainFromAuthorizationEndpoint(cognitoSecrets.authorization_endpoint);
    const cognitoOauth: CognitoOauth = {
      domain: domainValue,
      redirectSignOut: AppConfigService.settings.redirectURL,
      redirectSignIn: AppConfigService.settings.redirectURL,
      responseType: 'code'
    };
    return {
      userPoolId: poolId,
      userPoolWebClientId: cognitoSecrets.client_id,
      region: regionValue,
      oauth: cognitoOauth
    };
  }

  private getPoolIdFromIssuer(issuer: string): string {
    return issuer.substring(issuer.lastIndexOf('/') + 1);
  }

  private getRegionFromPoolId(poolId: string): string {
    return poolId.substring(0, poolId.lastIndexOf('_'));
  }

  private getDomainFromAuthorizationEndpoint(authorizationEndpoint: string): string {
    const domain = authorizationEndpoint.replace('https://', '');
    return domain.substring(0, domain.indexOf('/'));
  }
}
